import(/* webpackMode: "eager" */ "/home/frontend/LuckyNetwork/LuckyNetwork-LandingWebV5/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/frontend/LuckyNetwork/LuckyNetwork-LandingWebV5/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/frontend/LuckyNetwork/LuckyNetwork-LandingWebV5/public/img/apply/developer_apply.png");
;
import(/* webpackMode: "eager" */ "/home/frontend/LuckyNetwork/LuckyNetwork-LandingWebV5/public/img/apply/staff_apply.png");
;
import(/* webpackMode: "eager" */ "/home/frontend/LuckyNetwork/LuckyNetwork-LandingWebV5/public/img/landing/footer/luckynetwork_footerlogo.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/frontend/LuckyNetwork/LuckyNetwork-LandingWebV5/src/components/landing/navigation/Header.tsx");
